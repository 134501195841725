<template>
  <div>
    <div>
      <h1
        class="header-text pb-3"
        ref="headerEditProfile"
        aria-labelledby="header for Retired Mentors page"
        data-cy="header-admin-reports-retired"
        autofocus
      >
        Retired Mentors
      </h1>
      <div class="text-right flex-auto py-6">
        <Button class="" text="Download Data" @click="downloadData('retired_mentors.csv', records)" />
      </div>
    </div>
    <div class="box-style mb-6">
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div v-else>
        <dynamic-table
          :records="this.records"
          :fields="tableConfig"
          no-data-msg=""
          striped
          hover
        >
          <template #cell(last_updated)="{ item }">
            {{ this.FormatDate(item.last_updated) }}
          </template>
        </dynamic-table>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import { get } from "@/composables/httpUtil";
import { downloadData } from "@/composables/downloadUtils";
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/reusable-components/base-level-components/Button";
import { FormatDate } from "@/composables/date_utils";

export default {
  name: "Pairings",
  components: { LoadingSpinner, DynamicTable, Button },
  data() {
    return {
      isLoading: true,
      records: [],
      tableConfig: [
        {
          display: "Last Updated",
          name: "last_updated",
          sortable: false,
          class: "",
        },
        {
          display: "Prefix",
          name: "prefix",
          sortable: false,
          class: "",
        },
        {
          display: "First Name",
          name: "first_name",
          sortable: false,
          class: "",
        },
        {
          display: "Middle Name",
          name: "middle_name",
          sortable: false,
          class: "",
        },
        {
          display: "Last Name",
          name: "last_name",
          sortable: false,
          class: "",
        },
        {
          display: "Suffix",
          name: "suffix",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor Status",
          name: "mentor_status",
          sortable: false,
          class: "",
        },
        {
          display: "Pref. Acad. Lvl.",
          name: "acad_level",
          sortable: false,
          class: "",
        },
        {
          display: "Alumnus Year",
          name: "alumnus_year",
          sortable: false,
          class: "",
        },
        {
          display: "Employer Name",
          name: "employer_name",
          sortable: false,
          class: "",
        },
        {
          display: "Position",
          name: "position",
          sortable: false,
          class: "",
        },
        {
          display: "Email Type",
          name: "email_type",
          sortable: false,
          class: "",
        },
        {
          display: "Email Address",
          name: "email_address",
          sortable: false,
          class: "",
        },
        {
          display: "Phone Number Type",
          name: "phone_type",
          sortable: false,
          class: "",
        },
        {
          display: "Phone Number",
          name: "phone_number",
          sortable: false,
          class: "",
        },
        {
          display: "Address Type",
          name: "address_type",
          sortable: false,
          class: "",
        },
        {
          display: "Address Line 1",
          name: "address_line_1",
          sortable: false,
          class: "",
        },
        {
          display: "Address Line 2",
          name: "address_line_2",
          sortable: false,
          class: "",
        },
        {
          display: "City",
          name: "city",
          sortable: false,
          class: "",
        },
        {
          display: "State",
          name: "state",
          sortable: false,
          class: "",
        },
        {
          display: "Zip Code",
          name: "zip_code",
          sortable: false,
          class: "",
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    downloadData,
    FormatDate,
    getData() {
      let url = `admin/reports/retired_mentors`;
      get(url)
        .then((results) => {
          this.records = results;
        })
        .catch((error) => {
          console.log("error in catch statement", error);
          this.records = [];
        })
        .finally(() => (this.isLoading = false));
    },
    ...mapActions(["fetchLookupResource"]),
  },
  computed: {
    ...mapGetters(["lookup_current_term"]),
  },
};
</script>

<style scoped></style>
